<template>
  <section id="freight-charges">
    <b-card>
      <b-row>
        <b-col xl="6" md="6">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="CalendarIcon" />
            </b-input-group-prepend>
            <flat-pickr v-model="dateRange" placeholder="Date Range" class="form-control" :config="{ mode: 'range' }"
              @on-change="changeDateRange" />
          </b-input-group>
        </b-col>
        <b-col xl="6" md="6">
          <v-select v-model="selectedMethod" placeholder="Shipping type" label="title" :options="allMethods"
            @input="changeSelectedMethod" />
        </b-col>
      </b-row>
    </b-card>
    <b-overlay :show="showAvg" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
      <b-row>
        <b-col lg="6" sm="6">
          <b-card no-body>
            <b-card-body class="d-flex justify-content-between align-items-center">
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder text-info">
                  {{ totalReceived | formatCurrencyNumber }}
                </h2>
                <span>Total Received Value</span>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col lg="6" sm="6">
          <b-card no-body>
            <b-card-body class="d-flex justify-content-between align-items-center">
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ totalInvoiced | formatCurrencyNumber }}
                </h2>
                <span>Total Invoiced Value</span>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="6" sm="6">
          <b-card no-body>
            <b-card-body class="d-flex justify-content-between align-items-center">
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder text-info">
                  {{ avgReceived | formatCurrencyNumber }}
                </h2>
                <span>Avg. Received Value By Month</span>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col lg="6" sm="6">
          <b-card no-body>
            <b-card-body class="d-flex justify-content-between align-items-center">
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ avgInvoiced | formatCurrencyNumber }}
                </h2>
                <span>Avg. Invoiced Value By Month</span>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            Freight charges
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-freight-charges" />
          </h4>
          <b-popover target="popover-freight-charges" triggers="hover" placement="bottom">
            <span>No data</span>
          </b-popover>
        </b-card-header>
        <ECharts ref="freightChargesChart" :options="freightChargesChart" style="width: 100%; height: 700px;" />
      </b-card>
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            Freight charges by years
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
              id="popover-freight-charges-years" />
          </h4>
          <b-popover target="popover-freight-charges-years" triggers="hover" placement="bottom">
            <span>No data</span>
          </b-popover>
        </b-card-header>
        <ECharts ref="freightChargesByYearChart" :options="freightChargesByYearChart"
          style="width: 100%; height: 700px;" />
      </b-card>
    </b-overlay>
  </section>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BCardBody,
  BCardHeader,
  BPopover,
  BRow,
  BCol,
  BOverlay,
  BBadge,
  BInputGroup,
  BInputGroupPrepend,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import flatPickr from 'vue-flatpickr-component';
import axios from 'axios';
import ECharts from 'vue-echarts';
import 'echarts';

import 'echarts/lib/chart/bar';
import 'echarts/lib/component/tooltip';

const currentDate = new Date();
const twelveMonthsAgo = new Date();
twelveMonthsAgo.setMonth(currentDate.getMonth() - 11);
const formatMonth = (date) => String(date.getMonth() + 1).padStart(2, '0');
const formatDate = (date) => String(date.getDate()).padStart(2, '0');

export default {
  components: {
    BCard,
    BCardTitle,
    BCardBody,
    BCardHeader,
    BPopover,
    BRow,
    BCol,
    BOverlay,
    BBadge,
    BInputGroup,
    BInputGroupPrepend,
    vSelect,
    flatPickr,
    ECharts,
  },
  data() {
    return {
      show: true,
      showAvg: true,
      oldDateRange: `${twelveMonthsAgo.getFullYear()}-${formatMonth(twelveMonthsAgo)}-01 to ${currentDate.getFullYear()}-${formatMonth(currentDate)}-${formatDate(currentDate)}`,
      dateRange: `${twelveMonthsAgo.getFullYear()}-${formatMonth(twelveMonthsAgo)}-01 to ${currentDate.getFullYear()}-${formatMonth(currentDate)}-${formatDate(currentDate)}`,
      selectedMethod: 'All',
      allMethods: ['All', 'DHL', 'Schenker', 'Deutsche Post', 'Direktlieferung'],
      avgReceived: '',
      avgInvoiced: '',
      totalReceived: '',
      totalInvoiced: '',
      queryParamsIn: {},
      queryParamsOut: {},
      queryParamsOutJTL: {},
      freightChargesChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              let value;
              if (param.value) {
                value = parseFloat(param.value).toLocaleString('de-DE');
              }
              else {
                value = 'No data';
              }
              result += `${param.marker} ${param.seriesName}: ${value} €<br/>`;
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      freightChargesByYearChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              let value;
              if (param.value) {
                value = parseFloat(param.value).toLocaleString('de-DE');
              }
              else {
                value = 'No data';
              }
              result += `${param.marker} ${param.seriesName}: ${value} €<br/>`;
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
    }
  },
  async created() {
    const dateRange = this.dateRange.split(' to ');
    this.queryParamsIn.from_date = dateRange[0];
    this.queryParamsIn.to_date = dateRange[1];
    this.queryParamsOut.from_date = dateRange[0];
    this.queryParamsOut.to_date = dateRange[1];
    this.queryParamsOutJTL.from_date = dateRange[0];
    this.queryParamsOutJTL.to_date = dateRange[1];
    this.queryParamsOutJTL.method = '%PPL %';

    try {
      await this.getFreigntCharges();
    } catch (error) {
      if (error.response.status === 401) {
        this.$router.push({ name: 'login' });
      }
    }
  },
  methods: {
    getMonthsYearsFromDateRange(dateRange) {
      // Split the date range string to get start and end dates
      const [startDateStr, endDateStr] = dateRange.split(' to ');

      // Convert the start and end date strings to Date objects
      const startDate = new Date(startDateStr);
      const endDate = new Date(endDateStr);

      // Generate the list of 'month.year' strings
      let current = new Date(startDate);
      const monthsAndYears = [];

      while (current <= endDate) {
        const month = current.getMonth() + 1; // JavaScript months are 0-indexed
        const year = current.getFullYear();

        monthsAndYears.push(`${month}.${year}`);

        current.setMonth(current.getMonth() + 1);
      }

      return monthsAndYears;
    },
    processFreightCharges(data, monthsYears, extraData = []) {
      const freightCharges = [];
      const freightChargesMap = new Map(data.map(item => [item.month_year, item.total_amount]));
      const extraDataMap = new Map(extraData.map(item => [item.month_year, item.total_amount]));

      for (let monthYear of monthsYears) {
        const baseAmount = freightChargesMap.get(monthYear) || 0.0;
        const extraAmount = extraDataMap.get(monthYear) || 0.0;
        freightCharges.push(parseFloat(baseAmount) + parseFloat(extraAmount));
      }

      return freightCharges;
    },
    processFreightChargesByYear(data, years, extraData = []) {
      const freightCharges = [];
      const freightChargesMap = new Map(data.map(item => [parseInt(item.year), parseFloat(item.total_amount)]));
      const extraDataMap = new Map(extraData.map(item => [parseInt(item.year), parseFloat(item.total_amount)]));

      for (let year of years) {
        const baseAmount = freightChargesMap.get(year) || 0.0;
        const extraAmount = extraDataMap.get(year) || 0.0;
        freightCharges.push(parseFloat(baseAmount) + parseFloat(extraAmount));
      }

      return freightCharges;
    },
    calculateTotalAndAverage(charges) {
      const total = charges.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
      const average = charges.length > 0 ? total / charges.length : 0;
      return { total, average };
    },
    updateChart(data) {
      const dataFreightChargesIn = data[0].data.results;
      const dataFreightChargesOut = data[1].data;
      let dataFreightChargesOutJTL;
      if (this.selectedMethod == 'All' || this.selectedMethod == 'Direktlieferung') {
        dataFreightChargesOutJTL = data[2].data.results;
      }
      else {
        dataFreightChargesOutJTL = [];
      }

      let monthsYears = this.getMonthsYearsFromDateRange(this.dateRange);
      this.freightChargesChart.xAxis.data = monthsYears;

      if (dataFreightChargesIn.length > 0) {
        const freightChargesIn = this.processFreightCharges(dataFreightChargesIn, monthsYears);
        this.freightChargesChart.series[0] = {
          name: "Received",
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: freightChargesIn,
        };

        const { total, average } = this.calculateTotalAndAverage(freightChargesIn);
        this.totalReceived = total;
        this.avgReceived = average;
      } else {
        this.freightChargesChart.series[0] = {
          name: "Received",
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: [],
        };
        this.totalReceived = 0.0;
        this.avgReceived = 0.0;
      }

      if (dataFreightChargesOut.length > 0) {
        const freightChargesOut = this.processFreightCharges(dataFreightChargesOut, monthsYears, dataFreightChargesOutJTL);
        this.freightChargesChart.series[1] = {
          name: "Invoiced",
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: freightChargesOut,
        };

        const { total, average } = this.calculateTotalAndAverage(freightChargesOut);
        this.totalInvoiced = total;
        this.avgInvoiced = average;
      }
      else if (dataFreightChargesOutJTL.length > 0) {
        const freightChargesOut = this.processFreightCharges(dataFreightChargesOutJTL, monthsYears);
        this.freightChargesChart.series[1] = {
          name: "Invoiced",
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: freightChargesOut,
        };

        const { total, average } = this.calculateTotalAndAverage(freightChargesOut);
        this.totalInvoiced = total;
        this.avgInvoiced = average;
      } else {
        this.freightChargesChart.series[1] = {
          name: "Invoiced",
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: [],
        };
        this.totalInvoiced = 0.0;
        this.avgInvoiced = 0.0;
      }

      const dataFreightChargesInByYear = data[3].data.results;
      const dataFreightChargesOutByYear = data[4].data;
      let dataFreightChargesOutJTLByYear;
      if (this.selectedMethod == 'All' || this.selectedMethod == 'Direktlieferung') {
        dataFreightChargesOutJTLByYear = data[5].data.results;
      }
      else {
        dataFreightChargesOutJTLByYear = [];
      }

      const years = dataFreightChargesInByYear.map(item => item.year);
      if (dataFreightChargesInByYear.length > 0) {
        const freightChargesInByYear = this.processFreightChargesByYear(dataFreightChargesInByYear, years);
        this.freightChargesByYearChart.series[0] = {
          name: "Received",
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: freightChargesInByYear,
        };
      } else {
        this.freightChargesByYearChart.series[0] = {
          name: "Received",
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: [],
        };
      }

      if (dataFreightChargesOutByYear.length > 0) {
        const freightChargesOutByYear = this.processFreightChargesByYear(dataFreightChargesOutByYear, years, dataFreightChargesOutJTLByYear);
        this.freightChargesByYearChart.series[1] = {
          name: "Invoiced",
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: freightChargesOutByYear,
        };
      }
      else if (dataFreightChargesOutJTLByYear.length > 0) {
        const freightChargesOutByYear = this.processFreightChargesByYear(dataFreightChargesOutJTLByYear, years);
        this.freightChargesByYearChart.series[1] = {
          name: "Invoiced",
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: freightChargesOutByYear,
        };
      } else {
        this.freightChargesByYearChart.series[1] = {
          name: "Invoiced",
          type: 'bar',
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'inside',
            distance: 5,
            align: 'center',
            verticalAlign: 'middle',
            rotate: 90,
            formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
            fontSize: 10,
            rich: {
              name: {}
            },
          },
          data: [],
        };
      }

      this.freightChargesByYearChart.xAxis.data = years;
    },
    async makeRequest(url, params) {
      return axios.get(url, {
        headers: {
          Authorization: `JWT ${this.$store.state.jwt}`,
          'Content-Type': 'application/json',
        },
        params,
      });
    },
    async getFreigntCharges() {
      this.show = true;
      this.showAvg = true;
      try {
        const response = await axios.all([
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/freight-charges-in/`, this.queryParamsIn),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/freight-charges-out-invoices-by-month/`, this.queryParamsOut),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/freight-charges-out-jtl/`, this.queryParamsOutJTL),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/freight-charges-in-by-year/`, this.queryParamsIn),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/freight-charges-out-invoices-by-year/`, this.queryParamsOut),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/freight-charges-out-jtl-by-year/`, this.queryParamsOutJTL),
        ]);
        this.updateChart(response);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.show = false;
        this.showAvg = false;
      }
    },
    async changeSelectedMethod() {
      const currentDate = new Date();
      const oneYearAgo = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
      let fromDate = `${oneYearAgo.getFullYear()}-${oneYearAgo.getMonth() + 1}-${oneYearAgo.getDate()}`;
      let toDate = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`;

      if (this.dateRange) {
        const dateRange = this.dateRange.split(' to ');
        fromDate = dateRange[0];
        toDate = dateRange[1];
      }

      this.queryParamsIn.from_date = fromDate;
      this.queryParamsIn.to_date = toDate;
      this.queryParamsOut.from_date = fromDate;
      this.queryParamsOut.to_date = toDate;
      this.queryParamsOutJTL.from_date = fromDate;
      this.queryParamsOutJTL.to_date = toDate;

      if (this.selectedMethod !== 'All') {
        if (this.selectedMethod == 'Schenker') {
          this.queryParamsIn.method = '%Spedition%';
        }
        else if (this.selectedMethod == 'Deutsche Post') {
          this.queryParamsIn.method = '%Express%';
        }
        else {
          this.queryParamsIn.method = '%' + this.selectedMethod + '%';
        }
        this.queryParamsOut.method = '%' + this.selectedMethod + '%';
        this.queryParamsOutJTL.method = '%PPL %';
      }
      else {
        delete this.queryParamsIn['method'];
        delete this.queryParamsOut['method'];
      }

      await this.getFreigntCharges();

      this.$nextTick(() => {
        this.$refs.freightChargesChart.refresh();
        this.$refs.freightChargesByYearChart.refresh();
      });
    },
    async changeDateRange() {
      const dateRange = this.dateRange.split(' to ');
      if (dateRange.length > 1 && this.dateRange != this.oldDateRange) {
        this.queryParamsIn.from_date = dateRange[0];
        this.queryParamsIn.to_date = dateRange[1];
        this.queryParamsOut.from_date = dateRange[0];
        this.queryParamsOut.to_date = dateRange[1];
        this.queryParamsOutJTL.from_date = dateRange[0];
        this.queryParamsOutJTL.to_date = dateRange[1];
        await this.getFreigntCharges();
        this.oldDateRange = this.dateRange;

        this.$nextTick(() => {
          this.$refs.freightChargesChart.refresh();
          this.$refs.freightChargesByYearChart.refresh();
        });
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
